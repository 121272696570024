





























import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { Pages } from '@/inc/types'

/* eslint-disable camelcase */
const subpages: Pages = {}
/* eslint-enable camelcase */

export default defineComponent({
  name: 'subpage',
  components: {},
  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
      subpages,
    }
  },
})
