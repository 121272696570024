






import Vue, { VueConstructor } from 'vue'
import { computed, defineComponent } from '@vue/composition-api'

import { useState } from '@u3u/vue-hooks'
import { Pages } from '@/inc/types'

import Home from '@/views/Home.vue'
import Subpage from '@/views/pages/Subpage.vue'

// prettier-ignore
const pages: Pages = {
  homepage: Home,
  page: Subpage,
}

export default defineComponent({
  name: 'page',
  setup() {
    const state = useState(['resource'])

    const component = computed<VueConstructor<Vue>>(() => pages[state.resource.value?.template] || Subpage)

    return {
      component,
    }
  },
})
